import React, { useState, useEffect } from "react"
import { Typography } from "@mui/material"
import {
  SummaryContainer,
  SummaryHeader,
  SummaryDetailsContainer,
  PriceContainer,
  PriceRow,
  FormRow,
  CouponContainer,
  CouponText,
  Footer,
} from "./styles"
import TextField from "../TextField"
import { Button } from "../Button"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { useAppDispatch } from "../../redux/configureStore"
import { checkCoupon } from "../../redux/coupon"
import { updatePrice } from "../../redux/reprice"
import { v4 as uuidv4 } from "uuid"
import DiscountIcon from "@mui/icons-material/Discount"
import { getProration } from "../../redux/network/prorate"
import CloseIcon from "@mui/icons-material/Close"
import Tooltip from "@mui/material/Tooltip"
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined"
import { useAuth } from "../../providers/AuthProvider"
import { Product } from "../../redux/product"
import FormModal from "../FormModal"
import AccountForm from "../AccountForm"
import { useOrgDiscount } from "../../hooks/useOrgDiscount"
import countryList from "react-select-country-list"
import states from "states-us"

type Props = {
  products?: Product[]
  couponCode: string
  setCouponCode: (value: string) => void
  setPage: (num: number) => void
  page: number
}

const View = ({
  products,
  couponCode,
  setCouponCode,
  setPage,
  page,
}: Props) => {
  const auth = useAuth()
  const orgDiscount = useOrgDiscount();
  const [discountFieldName, updateDiscountFieldName] = useState("")
  const [discount, updateDiscount] = useState(0)
  const [subtotal, updateSubtotal] = useState(0)
  const [taxAmount, updateTaxAmount] = useState(-1)
  const [total, updateTotal] = useState(0)
  const [credit, updateCredit] = useState(0)
  const [couponField, setCouponField] = useState(couponCode)
  const [proratedAdjustment, updateProratedAdjustment] = useState(0)
  const [errorMessage, updateError] = useState("")
  const [billingPeriodDiscount, updateBillingPeriodDiscount] = useState(0)
  const [billingPeriodRangeStart, updateBillingPeriodRangeStart] = useState("")
  const [billingPeriodRangeEnd, updateBillingPeriodRangeEnd] = useState("")
  const [nextBillingPeriodStart, updateNextBillingPeriodStart] = useState("")
  const [billingPeriodCredit, updateBillingPeriodCredit] = useState(0)
  const [carryOverCoupon, updateCarryOverCoupon] = useState(false)
  const [upgradeSameFrequencyType, updateUpgradeSameFrequencyType] =
    useState(true)
  const [frequency, setFrequency] = useState("mo")
  const [showCoupon, setShowCoupon] = useState(false)
  const [open, setOpen] = useState(false)

  const dispatch = useAppDispatch()

  const cartDetails = useSelector(
    (state: AppState) => state.reprice.cartDetails
  )

  const cartProrateDetails = useSelector(
    (state: AppState) => state?.prorate?.cartProrateDetails
  )

  const billingInfoStoreState = useSelector(
    (state: AppState) => state.billingInfo.paymentMethods?.payment_methods
  )

  const subscription = useSelector(
    (state: AppState) => state.subscription.activeSubscription
  )?.filter((subscription) => subscription?.name.includes("ARE 5.0"))

  const formatNumberToDollars = (num: number) => {
    let negativeNumber = false
    if (num < 0) negativeNumber = true

    num = Math.abs(num)
    num /= 100

    const numberWithCommas = num
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",")

    if (negativeNumber) return `-$${numberWithCommas}`

    return `$${numberWithCommas}`
  }

  const handleSubmit = async (
    e?: any,
    skipCouponCheck?: boolean,
    removeCoupon?: boolean
  ) => {
    if (e) {
      e.preventDefault()
      // do nothing if nothing is in the coupon input field
      if (!e.target[0].value.length) return

      // clear out coupon input field after each submission
      e.target[0].value = ""

      if (discountFieldName.toLocaleUpperCase() === couponField.toUpperCase()) {
        updateError("Coupon already applied.")
        return
      }

      // do nothing if cart product matches active subscription
      if (
        products &&
        products.length > 0 &&
        subscription &&
        products[0]?.name.includes(subscription[0]?.name)
      )
        return
    }

    if (!skipCouponCheck) window.dataLayer.push({ event: "applyCoupon" })

    if (products && products.length > 0) {
      const product = products[0]
      const { id } = product

      try {
        const { type }: any = await dispatch(
          checkCoupon({ couponCode: couponField, id })
        )

        if (type === checkCoupon.rejected.type && !skipCouponCheck) {
          updateError(`Invalid coupon.`)
          return
        }

        if (type === checkCoupon.fulfilled.type || skipCouponCheck) {
          if (
            Number(couponField.slice(-2)) <
              Number(discountFieldName.slice(-2)) &&
            carryOverCoupon
          ) {
            updateError(
              "The coupon you are trying to apply has a lower discount than the existing coupon on your account."
            )
            return
          }

          updateCarryOverCoupon(false)
          updateError("")

          const frequency = compareSubscriptionFrequency()
          updateUpgradeSameFrequencyType(frequency)

          if (!removeCoupon) {
            setCouponCode(couponField)
          }

          if (!billingInfoStoreState || subscription) {
            repriceCoupon(removeCoupon)
          } else {
            prorateCoupon(removeCoupon)
          }
        }
      } catch (err) {
        return
      }
    }
  }

  // For users without a subscription
  const repriceCoupon = async (removeCoupon?: boolean) => {
    const cartItems: any = []
    products?.forEach((product) => {
      const item = {
        id: product.id,
        price: (product.amount / 100).toFixed(2),
        qty: "1",
        name: product.name,
      }
      cartItems.push(item)
    })

    let address = {}

    if (billingInfoStoreState && billingInfoStoreState[0]) {
      const { address_1, address_2, city, state, postal_code, country } =
        billingInfoStoreState[0]?.address

      address = {
        address1: address_1,
        address2: address_2,
        city,
        state,
        postal_code,
        country,
      }
    }

    const formAvailable = document?.getElementById("billingForm")
    if (formAvailable) {
      const { formAddress1, formAddress2, formCity, formState, formCountry, formPostalCode } = checkFormValues()

      if (formAddress1 && formCity && formState && formCountry) {
        address = {
          address1: formAddress1,
          address2: formAddress2,
          city: formCity,
          state: formState,
          postal_code: formPostalCode,
          formCountry,
        }
      }
    }

    const fields = {
      items: cartItems,
      coupon: removeCoupon ? "" : couponField,
      address,
    }

    const { payload } = await dispatch(updatePrice(fields))
    const { discount, sub_total, tax, items } = payload
    const couponName = items[0].coupon

    if (!removeCoupon) updateDiscountFieldName(`${couponName}`.toUpperCase())
    updateDiscount(discount)

    if (!address || !Object.keys(address).length) return
    updateTaxAmount(tax)
    updateSubtotal(sub_total)
  }

  const prorateCoupon = async (removeCoupon?: boolean) => {
    if (!billingInfoStoreState || !subscription) return

    const cartItems: any = []

    products?.forEach((product: any) => {
      const item = {
        id: product.id,
        price: (product.amount / 100).toFixed(2),
        qty: "1",
        name: product.name,
        target_subscription_id: subscription[0]?.id,
      }

      cartItems.push(item)
    })

    const { address_1, address_2, city, state, postal_code, country } =
      billingInfoStoreState[0].address

    const fields = {
      items: cartItems,
      coupon: removeCoupon ? "" : couponField,
      address: {
        address1: address_1,
        address2: address_2,
        city,
        state,
        postal_code,
        country,
      },
    }

    const formAvailable = document?.getElementById("billingForm")
    // only run if billing form is available
    if (formAvailable) {
      const { formAddress1, formAddress2, formCity, formState, formCountry } =
        checkFormValues()

      if (formAddress1 && formCity && formState && formCountry) {
        fields.address.address1 = formAddress1
        fields.address.address2 = formAddress2
        fields.address.city = formCity
        fields.address.state = formState
        fields.address.country = formCountry
      } else {
        const { address_1, address_2, city, state, postal_code, country } =
          billingInfoStoreState[0].address

        fields.address.address1 = address_1
        fields.address.address2 = address_2
        fields.address.city = city
        fields.address.state = state
        fields.address.postal_code = postal_code
        fields.address.country = country
      }
    }

    const { payload } = await dispatch(getProration({ auth, ...fields }))

    const { sub_total, items } = payload

    if (couponCode) updateDiscountFieldName(couponCode.toUpperCase())
    if (removeCoupon) updateDiscount(discount)
    updateSubtotal(sub_total)

    items.forEach((item: any, idx: any) => {
      const {
        billing_period_credit,
        discount,
        billing_period_end,
        coupon_type,
        coupon,
      } = item
      if (billing_period_end) {
        updateBillingPeriodCredit(billing_period_credit)
        updateBillingPeriodDiscount(discount)

        const date = billing_period_end.split("-")

        const startDate = new Date()
        const startMonth = startDate.getMonth() + 1
        const startDay = startDate.getDate()
        const startYear = startDate.getFullYear().toString().slice(2)
        const billingStartDate = `${startMonth}/${startDay}/${startYear}`

        const endMonth = date[1]
        const endDay = formatDate(date[2])
        const endYear = formatDate(date[0].slice(2))
        const billingEndDate = `${endMonth}/${endDay}/${endYear}`

        const nextBillingPeriodStartDate = `${endMonth}/${
          Number(endDay) + 1
        }/${endYear}`

        updateBillingPeriodRangeStart(billingStartDate)
        updateBillingPeriodRangeEnd(billingEndDate)
        updateNextBillingPeriodStart(nextBillingPeriodStartDate)

        if (billing_period_credit) {
          updateBillingPeriodCredit(billing_period_credit)
        }
      }
      if (
        billing_period_credit === 0 &&
        products &&
        products[idx].amount - sub_total !== 0 &&
        !discount
      ) {
        // calculate billing period credit when loading cart if applicable
        updateBillingPeriodCredit((products[idx].amount - sub_total) * -1)
      }

      if (coupon_type === "carry-over") {
        updateCarryOverCoupon(true)
        updateDiscountFieldName(coupon)
        updateDiscount(discount)
      } else {
        updateCarryOverCoupon(false)
        updateDiscountFieldName(coupon)
        updateDiscount(discount)
      }
    })
  }

  const checkFormValues = () => {
    const formAddress1 = (
      document?.getElementById("address1") as HTMLInputElement
    ).value

    const formAddress2 = (
      document?.getElementById("address2") as HTMLInputElement
    ).value

    const formCity = (document?.getElementById("city") as HTMLInputElement)
      .value
      
    const currentState = (
      document?.getElementById("state") as HTMLInputElement
    ).children[1].children[0].innerHTML

    const filteredState = states.find(state => state.name === currentState)
    const formState = filteredState?.abbreviation

    const formPostalCode = (
      document?.getElementById("postalCode") as HTMLInputElement
    ).value

    const currentCountry = (
      document?.getElementById("country") as HTMLInputElement
    ).children[1].children[0].innerHTML
    const filteredCountry = countryList().getData().find(country => country.label === currentCountry)
    const formCountry = filteredCountry?.value

    return {
      formAddress1,
      formAddress2,
      formCity,
      formState,
      formCountry,
      formPostalCode
    }
  }

  const handleProration = () => {
    if (cartProrateDetails) {
      const { credit, discount, proration, sub_total, tax, total, items } =
        cartProrateDetails

      updateTaxAmount(tax)
      updateSubtotal(sub_total)
      updateTotal(total)
      updateCredit(credit)
      updateDiscount(discount)
      updateProratedAdjustment(proration)

      if (!products) return

      items.forEach((item: any, idx: any) => {
        const {
          billing_period_credit,
          discount,
          billing_period_end,
          coupon_type,
          coupon,
        } = item

        if (billing_period_end) {
          updateBillingPeriodDiscount(discount)
          const date = billing_period_end.split("-")

          const startDate = new Date()
          const startMonth = startDate.getMonth() + 1
          const startDay = startDate.getDate()
          const startYear = startDate.getFullYear().toString().slice(2)
          const billingStartDate = `${startMonth}/${startDay}/${startYear}`

          const endMonth = formatDate(date[1])
          const endDay = formatDate(date[2])
          const endYear = date[0].slice(2)
          const billingEndDate = `${endMonth}/${endDay}/${endYear}`

          const nextBillingPeriodStartDate = `${endMonth}/${Number(
            endDay
          )}/${endYear}`

          updateBillingPeriodRangeStart(billingStartDate)
          updateBillingPeriodRangeEnd(billingEndDate)
          updateNextBillingPeriodStart(nextBillingPeriodStartDate)

          if (billing_period_credit) {
            updateBillingPeriodCredit(billing_period_credit)
          }
        }

        if (
          billing_period_credit === 0 &&
          products[idx].amount - sub_total !== 0 &&
          !discount
        ) {
          // calculate billing period credit when loading cart if applicable
          updateBillingPeriodCredit((products[idx].amount - sub_total) * -1)
        }

        if (coupon_type === "carry-over") {
          const sameFrequency = compareSubscriptionFrequency()
          updateUpgradeSameFrequencyType(sameFrequency)

          updateCarryOverCoupon(true)
          updateDiscountFieldName(coupon)
          updateDiscount(discount)
        }
      })
    }
  }

  // Check if active subscription and cart product are same frequency of year->year or month->month
  const compareSubscriptionFrequency = () => {
    if (products && products.length > 0 && subscription) {
      return (
        (products[0]?.name.includes("12-Month") &&
          subscription[0]?.name.includes("12-Month")) ||
        (products[0]?.name.includes("1-Month") &&
          subscription[0]?.name.includes("1-Month"))
      )
    }

    return false
  }

  const removeCoupon = () => {
    if (products && products.length) {
      setCouponCode("")
      setCouponField("")
      updateDiscountFieldName("")
      handleSubmit(null, true, true)
    }
  }

  const formatDate = (value: string) => {
    if (value.indexOf("0") === 0) {
      return value.slice(1)
    }
    return value
  }

  const handleClick = () => {
    if (!products) return

    const product = products[0]

    window.dataLayer.push({
      event: "proceedToCheckout",
      ecommerce: {
        items: [
          {
            item_name: `${product?.name}`,
            item_id: `${product?.id}`,
            price: (product?.amount / 100).toFixed(2),
            item_category: ``,
            item_category2: ``,
            quantity: "1",
          },
        ],
      },
    })

    if (auth.isAuthenticated) {
      // display PaymentMethods component for existing users to review their existing payment methods
      setPage(1)
      return
    }
    // display account creation form for new users
    setOpen(true)
  }

  useEffect(() => {
    if (
      // logged in without active subscription
      cartDetails
    ) {
      const { tax, total, address, discount, sub_total, items }: any =
        cartDetails

      // Clear out tax/total if no user exits cc form and has no saved payment method
      if (!address || Object.keys(address).length === 0) {
        updateSubtotal(sub_total)
        updateTaxAmount(-1)
        return
      }

      const couponName = items[0].coupon
      if (couponCode) {
        updateDiscountFieldName(`${couponName}`.toUpperCase())

        updateDiscount(discount)
      }
      updateSubtotal(sub_total)

      if (tax === 0 && address && Object.keys(address).length) {
        updateTaxAmount(0)
        updateTotal(total)
      } else if (tax) {
        updateTaxAmount(tax)
        updateTotal(total)
      }
    } else if (products && products.length && !cartDetails) {
      const { amount } = products[0]
      updateSubtotal(amount)
      updateTotal(amount)
    }

    // eslint-disable-next-line
  }, [products, cartDetails])

  useEffect(() => {
    if (auth.isAuthenticated) {
      handleProration()
    }
    // eslint-disable-next-line
  }, [cartProrateDetails])

  useEffect(() => {
    if (auth.isAuthenticated && !cartProrateDetails) {
      repriceCoupon()
    }
    // eslint-disable-next-line
  }, [billingInfoStoreState])

  useEffect(() => {
    if (products && products.length) {
      const freq = Number(products[0].duration) === 12 ? "yr" : "mo"
      setFrequency(freq)
    }
  }, [products])

  useEffect(() => {
    if (process.env.REACT_APP_COUPON_CODE && products && !orgDiscount.isOrgCheckout()) {
      setCouponCode(process.env.REACT_APP_COUPON_CODE)
      updateUpgradeSameFrequencyType(false)
      repriceCoupon()
    }
    // eslint-disable-next-line
  }, [products, billingInfoStoreState])

  return products ? (
    <SummaryContainer>
      <SummaryHeader>
        <Typography variant="h4">
          Order summary
          {subscription &&
          products &&
          products.length &&
          subscription[0]?.name !== products[0]?.name ? (
            <Tooltip
              title={
                <Typography
                  variant="h6"
                  style={{
                    fontSize: 14,
                    background: "white",
                    color: "black",
                    padding: 8,
                    border: "1px solid black",
                    borderRadius: 4,
                  }}
                >
                  {billingPeriodCredit ? (
                    <>
                      <b>Billing Period Credit </b>
                      is the credit on your account for the unused time on your
                      current subscription for the billing period{" "}
                      {billingPeriodRangeStart} - {billingPeriodRangeEnd}.
                      <br />
                      <br />
                    </>
                  ) : null}
                  <b>Prorated Adjustment </b>
                  is the amount off of your new subscription for the billing
                  period {billingPeriodRangeStart} - {billingPeriodRangeEnd}.
                  <br />
                  <br />
                  On {nextBillingPeriodStart} you will begin paying the regular
                  price, minus discounts if applicable, for your new
                  subscription.
                </Typography>
              }
            >
              <InfoOutlinedIcon />
            </Tooltip>
          ) : null}
        </Typography>
      </SummaryHeader>

      <SummaryDetailsContainer>
        <PriceContainer>
          <>
            {products.map((product) => (
              <PriceRow
                style={{
                  paddingBottom: 24,
                  display: "flex",
                  alignItems: "center",
                }}
                key={uuidv4()}
              >
                <Typography variant="body1" style={{ fontWeight: 350 }}>
                  {product.access_level.charAt(0).toUpperCase() +
                    product.access_level.slice(1)}{" "}
                  Membership
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 350 }}>
                  {formatNumberToDollars(product.amount)}
                  <span style={{ fontSize: "12px" }}>/{frequency}</span>
                </Typography>
              </PriceRow>
            ))}
          </>

          {products.length && !isNaN(Number(products[0]?.cap_limit)) ? (
            <PriceRow style={{ paddingBottom: 24 }}>
              <Typography variant="body1"># Open Seats</Typography>
              <Typography variant="body1" className="seat-count">
                {products[0]?.cap_limit}
              </Typography>
            </PriceRow>
          ) : null}

          {billingPeriodCredit ? (
            <>
              <PriceRow
                style={{
                  paddingBottom: 24,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 350,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  Billing Period Credit
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 350 }}>
                  {formatNumberToDollars(billingPeriodCredit)}
                </Typography>
              </PriceRow>
            </>
          ) : null}

          {billingPeriodDiscount && upgradeSameFrequencyType ? (
            <>
              <PriceRow
                style={{
                  paddingBottom: 24,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 350,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DiscountIcon
                    style={{ paddingRight: 6, height: 16, width: 16 }}
                  />
                  {discountFieldName}
                  {carryOverCoupon ? null : (
                    <CloseIcon
                      onClick={removeCoupon}
                      style={{ cursor: "pointer", paddingLeft: 6 }}
                    />
                  )}
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 350 }}>
                  {formatNumberToDollars(billingPeriodDiscount)}
                </Typography>
              </PriceRow>

              {carryOverCoupon ? (
                <PriceRow
                  style={{
                    paddingBottom: 24,
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "75%",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 350,
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    - Applied to the existing subscription on your account
                  </Typography>
                </PriceRow>
              ) : null}
            </>
          ) : null}

          <PriceRow
            style={{
              paddingTop: 24,
              paddingBottom: 24,
              borderTop: "0.5px solid #D8D8D8",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body1"
              style={{ fontWeight: 350, display: "flex", gap: 10 }}
            >
              Subtotal
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 350 }}>
              {formatNumberToDollars(subtotal)}
            </Typography>
          </PriceRow>
          {discountFieldName && discount !== 0 && !upgradeSameFrequencyType ? (
            <>
              <PriceRow
                style={{
                  paddingBottom: 24,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  style={{
                    fontWeight: 350,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <DiscountIcon
                    style={{ paddingRight: 6, height: 16, width: 16 }}
                  />
                  {discountFieldName}
                  {carryOverCoupon ? null : (
                    <CloseIcon
                      onClick={removeCoupon}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </Typography>
                <Typography variant="body1" style={{ fontWeight: 350 }}>
                  {formatNumberToDollars(discount)}
                </Typography>
              </PriceRow>

              {carryOverCoupon ? (
                <PriceRow
                  style={{
                    paddingBottom: 24,
                    display: "flex",
                    alignItems: "center",
                    maxWidth: "75%",
                  }}
                >
                  <Typography
                    variant="body2"
                    style={{
                      fontWeight: 350,
                      display: "flex",
                      alignItems: "center",
                      fontSize: 12,
                    }}
                  >
                    - Applied to your existing subscription on your account
                  </Typography>
                </PriceRow>
              ) : null}
            </>
          ) : null}

          <PriceRow
            style={{
              paddingBottom: 24,
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body1" style={{ fontWeight: 350 }}>
              Estimated Tax
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 350 }}>
              {taxAmount !== -1 ? formatNumberToDollars(taxAmount) : "..."}
            </Typography>
          </PriceRow>

          {proratedAdjustment ? (
            <PriceRow
              style={{
                paddingBottom: 24,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">Prorated Adjustment</Typography>
              <Typography variant="body1">
                {`${formatNumberToDollars(proratedAdjustment)}`}
              </Typography>
            </PriceRow>
          ) : null}

          {credit && total !== 0 ? (
            <PriceRow
              style={{
                paddingBottom: 24,
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography variant="body1">Credit on Account</Typography>
              <Typography variant="body1">
                {`${formatNumberToDollars(credit)}`}
              </Typography>
            </PriceRow>
          ) : null}

          {total <= 0 ? (
            <PriceRow
              style={{ paddingTop: 24, borderTop: "0.5px solid #D8D8D8" }}
            >
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                New credit on your account
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {total === 0
                  ? `+${formatNumberToDollars(Math.abs(credit))}`
                  : `+${formatNumberToDollars(Math.abs(total))}`}
              </Typography>
            </PriceRow>
          ) : (
            <PriceRow>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                Estimated Total
              </Typography>
              <Typography variant="body1" style={{ fontWeight: 700 }}>
                {taxAmount !== -1
                  ? `${formatNumberToDollars(total)}/${frequency}`
                  : "..."}
              </Typography>
            </PriceRow>
          )}
        </PriceContainer>

        {!orgDiscount.isOrgCheckout() &&
          <CouponContainer>
            {showCoupon ? (
              <form method={"POST"} onSubmit={handleSubmit} id="order-summary">
                <FormRow>
                  <TextField
                    className="couponInput"
                    label=""
                    placeholder="Add Coupon"
                    onChange={(e) => {
                      setCouponField(e.target.value)
                    }}
                    onBlur={() => updateError("")}
                    errorMessage={couponField ? errorMessage : null}
                    disabled={process.env.REACT_APP_COUPON_CODE ? true : false}
                  />
                  <Button
                    color={"secondary"}
                    children="Apply"
                    size="large"
                    type={"submit"}
                    disabled={process.env.REACT_APP_COUPON_CODE ? true : false}
                  ></Button>
                </FormRow>
              </form>
            ) : (
              <CouponText onClick={() => setShowCoupon(true)}>
                <DiscountIcon
                  style={{ paddingRight: 6, height: 16, width: 16 }}
                />
                Apply coupon code
              </CouponText>
            )}
          </CouponContainer>
        }
        <Footer>
          {(subscription &&
            subscription.length &&
            products[0].name.indexOf(subscription[0].name) !== -1) ||
          page ? null : (
            <Button
              color="primary"
              children={"Proceed to Checkout"}
              size="large"
              onClick={handleClick}
            />
          )}
        </Footer>

        {open && products[0].audience === "individual" ? (
          <FormModal
            modalOpen={open}
            updateModalState={setOpen}
            setPage={setPage}
          />
        ) : null}

        {open && products[0].audience === "group" ? (
          <AccountForm
            modalOpen={open}
            modalDisabled={() => setOpen(false)}
            setPage={setPage}
          />
        ) : null}
      </SummaryDetailsContainer>
    </SummaryContainer>
  ) : null
}

export default View
