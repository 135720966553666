import React, { useState, useCallback, useMemo, useRef } from "react"
import { useAppDispatch } from "../../redux/configureStore"
import update from "immutability-helper"
import * as Yup from "yup"
import { Typography } from "@mui/material"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import CreditIcons from "../../images/icons/creditIcons.svg"
import {
  StripeTextFieldCVC,
  StripeTextFieldExpiry,
  StripeTextFieldNumber,
} from "../StripeElement/StripeTextFields"
import { Button } from "../Button"
import TextField from "../TextField"
import ConfirmationModal from "../Confirmation"
import {
  CheckoutBox,
  BillingDetailsLabel,
  BlueBox,
  BlueLabel,
  BorderedBox,
  ButtonBox,
  CardInformationLabel,
  CheckoutHeader,
  FormBox,
  FormRow,
} from "./styles"
import {
  PaymentDetails,
  createNewPayment,
  notifySuccessPayment,
} from "../../redux/payment"
import { Product } from "../../redux/product"
import { Member } from "../../redux/account"
import countryList from "react-select-country-list"
import SelectField from "../SelectField"
import Label from "../Label"
import { useSelector } from "react-redux"
import { AppState } from "../../redux/configureStore"
import { updatePrice } from "../../redux/reprice"
import coupon from "../../redux/coupon"
import { removeCookie, setCookie } from "../../utils/cookies"
import { getSetupIntent, updatePaymentMethod } from "../../redux/creditcard"
import { getProration } from "../../redux/network/prorate"
import {
  createSubscription,
  attachSubscription,
} from "../../redux/network/payment"
import Backdrop from "@mui/material/Backdrop"
import CircularProgress from "@mui/material/CircularProgress"
import states from "states-us"
import { useAuth } from "../../providers/AuthProvider"
import { useOrgDiscount } from "../../hooks/useOrgDiscount"

const zipcodes = require("zipcodes")
const stateAbbreviations = require("states-abbreviations")

interface Props {
  products?: Product[]
  member?: Member
  isAREProduct?: boolean
  widget?: boolean
  setPage?: (num: number) => void
  updateCardFormVisible?: (val: boolean) => void
  updateBillingForm?: boolean
  getPaymentInfo?: () => void
  couponCode?: string
  setCouponCode?: (value: string) => void
}

const View = ({
  products,
  member,
  isAREProduct,
  widget,
  setPage,
  updateCardFormVisible,
  updateBillingForm,
  getPaymentInfo,
  couponCode,
  setCouponCode,
}: Props) => {
  useOrgDiscount();
  const auth = useAuth()
  const stripe = useStripe()
  const elements = useElements()
  const dispatch = useAppDispatch()
  const countryOptions = useMemo(() => countryList().getData(), [])
  const stateOptions = states.map(({ abbreviation, name }) => {
    return { value: abbreviation, label: name }
  })

  const addressRef: any = useRef({})
  const cityRef: any = useRef({})
  const stateRef: any = useRef({})
  const postalRef: any = useRef({})
  const countryRef: any = useRef({})

  // for confirmation page of ARE products
  const [open, setOpen] = useState(false)

  const [isDisabled, setIsDisabled] = useState(false)

  const [responseCartId, setResponseCartId] = useState("")
  const [responseClientSecret, setResponseClientSecret] = useState("")
  const [responseSubId, setResponseSubId] = useState("")
  const [taxId, setTaxId] = useState("")
  const [taxErrorActive, setTaxErrorActive] = useState(false)
  const [zipCodeErrorActive, setZipCodeErrorActive] = useState(false)

  const [backDropVisible, updateBackDropVisible] = useState(false)


  const items = useSelector(
    (state: AppState) => state.product.cartProducts?.products
  )

  const subscriptions = useSelector(
    (state: AppState) => state.subscription.activeSubscription
  )

  const billingInfoStoreState = useSelector(
    (state: AppState) => state.billingInfo.paymentMethods
  )

  const cartProrateDetails = useSelector(
    (state: AppState) => state.prorate.cartProrateDetails
  )

  const cartDetails = useSelector(
    (state: AppState) => state.reprice.cartDetails
  )

  const [formValues, setFormValues] = useState<PaymentDetails>({
    cardName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "US",
    postalCode: "",
    phoneNumber: "",
    phoneExtension: "",
    saveCreditCard: false,
  })

  const [touched, setTouched] = useState<any>({
    cardName: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    country: false,
    postalCode: false,
    phoneNumber: false,
    phoneExtension: false,
  })

  const [formErrors, setFormErrors] = useState({
    cardName: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    country: "",
    postalCode: "",
    phoneNumber: "",
    phoneExtension: "",
  })

  const validationErrors = {
    address1: "Please verify that your address, city, and state are correct.",
    city: "Please verify that your address, city, and state are correct.",
    state: "Please verify that your address, city, and state are correct.",
  }

  const [stripeState, setStripeState] = useState({
    cardNumberComplete: false,
    expiredComplete: false,
    cvcComplete: false,
    cardNumberError: "",
    expiredError: "",
    cvcError: "",
  })

  const { cardNumberError, expiredError, cvcError } = stripeState

  const onElementChange =
    (field: string, errorField: string) =>
    ({ complete, error = { message: null } }: any) => {
      if (complete === undefined) {
        setStripeState({
          ...stripeState,
          [field]: false,
          [errorField]: "Required",
        })
      } else {
        setStripeState({
          ...stripeState,
          [field]: complete,
          [errorField]: error.message,
        })
      }
    }

  const handlePaymentSubmit = async (e: React.FormEvent) => {
    e.preventDefault()

    if (!products) return

    window.dataLayer.push({ event: "payNow" })

    setIsDisabled(true)

    const isFormValid = await validationSchema.isValid(formValues, {
      abortEarly: false, // Prevent aborting validation after first error
    })

    setTouched({
      cardName: true,
      address1: true,
      address2: true,
      city: true,
      state: true,
      country: true,
      postalCode: true,
      phoneNumber: true,
      phoneExtension: true,
    })

    if (!isFormValid) {
      await validateFields(true)
      setIsDisabled(false)
      return
    }

    if (!stripe || !elements) {
      setIsDisabled(false)
      return
    }

    updateBackDropVisible(true)

    try {
      const paymentDetails = {
        member: {
          memberId: member?.memberId,
        },
        ...formValues,
      }

      let clientSecret = responseClientSecret
      let cartId = responseCartId
      let subId = responseSubId

      if (!responseCartId && !responseClientSecret && !responseSubId) {
        const { tax_rate_id }: any = cartDetails

        const { payload } = await dispatch(
          createNewPayment({
            auth,
            paymentDetails,
            products,
            coupon: couponCode,
            taxId: tax_rate_id,
          })
        )

        cartId = payload.cart_id
        clientSecret = payload.items[0].client_secret
        subId = payload.items[0].subscription_id

        setResponseCartId(cartId)
        setResponseClientSecret(clientSecret)
        setResponseSubId(subId)
      }

      if (
        createNewPayment.fulfilled.type === "/createNewPayment/fulfilled" ||
        (responseCartId && responseClientSecret && responseSubId)
      ) {
        const cardNumber = elements.getElement("cardNumber")
        if (cardNumber) {
          const options = {
            name: formValues.cardName,
            address: {
              postal_code: formValues.postalCode,
              line1: formValues.address1,
              line2: formValues.address2,
              city: formValues.city,
              state: formValues.state,
              country: formValues.country,
            },
            phone: formValues.phoneNumber,
          }

          stripe
            .confirmCardPayment(clientSecret, {
              payment_method: {
                card: cardNumber,
                billing_details: options,
              },
            })
            .then(async (result) => {
              if (result.error) {
                if (
                  [
                    "card_declined",
                    "processing_error",
                    "incomplete_number",
                  ].includes(result.error?.code!) &&
                  result.error.message
                ) {
                  setStripeState({
                    ...stripeState,
                    cardNumberError: result.error.message,
                    expiredError: "",
                    cvcError: "",
                  })
                }

                if (
                  ["incomplete_expiry", "expired_card"].includes(
                    result.error?.code!
                  ) &&
                  result.error.message
                ) {
                  setStripeState({
                    ...stripeState,
                    expiredError: result.error.message,
                    cardNumberError: "",
                    cvcError: "",
                  })
                }

                if (
                  ["incorrect_cvc", "incomplete_cvc"].includes(
                    result.error?.code!
                  ) &&
                  result.error.message
                ) {
                  setStripeState({
                    ...stripeState,
                    cvcError: result.error.message,
                    cardNumberError: "",
                    expiredError: "",
                  })
                }
                updateBackDropVisible(false)
                setResponseCartId("")
                setResponseClientSecret("")
                setResponseSubId("")
                setIsDisabled(false)
              } else {
                if (member?.memberId) {
                  const pmId = result.paymentIntent.payment_method

                  const successResponse = await dispatch(
                    notifySuccessPayment({
                      auth,
                      cartId,
                      subId,
                      pmId,
                    })
                  )

                  updateBackDropVisible(false)
                  if (isAREProduct) setOpen(true)

                  removeCookie("BKS_are5_Checkout_Product")
                  removeCookie("BKS_software_Checkout_Product")
                  if (setCouponCode) setCouponCode("")

                  const items = products.map((product) => ({
                    item_name: `${product?.name}`,
                    item_id: `${product?.id}`,
                    price: (product?.amount / 100).toFixed(2),
                    item_category: ``,
                    item_category2: ``,
                    quantity: "1",
                  }))

                  let productDetails

                  if (cartProrateDetails) {
                    productDetails = {
                      value: (cartProrateDetails.sub_total / 100).toFixed(2),
                      tax: (cartProrateDetails.tax / 100).toFixed(2),
                      credit: (cartProrateDetails.credit / 100).toFixed(2),
                    }
                  } else if (cartDetails) {
                    productDetails = {
                      value: (cartDetails?.sub_total / 100).toFixed(2),
                      tax: (cartDetails.tax / 100).toFixed(2),
                      credit: "0",
                    }
                  }

                  window.dataLayer.push({
                    event: "checkoutSuccess",
                    price: `Total price: ${productDetails?.value}`,
                    ecommerce: {
                      value: `${productDetails?.value}`,
                      credit: `${productDetails?.credit}`,
                      tax: `${productDetails?.tax}`,
                      transaction_id: `${successResponse.payload.invoice_id}`,
                      coupon,
                      items,
                    },
                  })
                }
              }
            })
        }
      }
    } catch (error) {
      updateBackDropVisible(false)
      setIsDisabled(false)
      window.dataLayer.push({ event: "checkoutSubmissionUnsuccessful" })
    }
  }

  const handleBillingUpdate = async (e: React.FormEvent) => {
    e.preventDefault()

    setIsDisabled(true)

    const isFormValid = await validationSchema.isValid(formValues, {
      abortEarly: false, // Prevent aborting validation after first error
    })

    setTouched({
      cardName: true,
      address1: true,
      address2: true,
      city: true,
      state: true,
      country: true,
      postalCode: true,
      phoneNumber: true,
      phoneExtension: true,
    })

    if (!isFormValid) {
      await validateFields(true)
      setIsDisabled(false)
      return
    }

    if (!stripe || !elements) {
      setIsDisabled(false)
      return
    }
    // restart cart if user is not logged in
    if (!auth.isAuthenticated) {
      window.location.reload()
      return
    }

    updateBackDropVisible(true)

    try {
      // for users that are updating their credit card
      if (
        (billingInfoStoreState &&
          billingInfoStoreState?.payment_methods &&
          billingInfoStoreState.payment_methods.length) ||
        // widgets can only be used to update credit cards
        widget
      ) {
        updateCardSetup()
        if (setPage) setPage(1)
      } else {
        handleFirstCardSetup()
      }
    } catch (error) {
      updateBackDropVisible(false)
    }
  }

  const updateCardSetup = async () => {
    if (!stripe || !elements) {
      setIsDisabled(false)
      return
    }

    const { payload, type } = await dispatch(getSetupIntent({ auth }))
    const { client_secret } = payload

    if (getSetupIntent.fulfilled.type !== type) return

    const cardNumber = elements.getElement("cardNumber")

    if (cardNumber) {
      const options = {
        name: formValues.cardName,
        address: {
          postal_code: formValues.postalCode,
          line1: formValues.address1,
          line2: formValues.address2,
          city: formValues.city,
          state: formValues.state,
          country: formValues.country,
        },
        phone: formValues.phoneNumber,
      }

      const result = await stripe.confirmCardSetup(client_secret, {
        payment_method: {
          card: cardNumber,
          billing_details: options,
        },
      })

      if (result.error) {
        handleStripeErrors(result)
        setIsDisabled(false)
        return
      }

      const pmId = result?.setupIntent?.payment_method

      if (pmId) {
        attachCard(pmId)
      }
    }
  }

  // Make payment for new users that created an account without completing checkout
  const handleFirstCardSetup = async () => {
    if (!stripe || !elements) {
      setIsDisabled(false)
      return
    }

    if (!products) return

    const paymentDetails = {
      member: {
        memberId: member?.memberId,
      },
      ...formValues,
    }

    let clientSecret = responseClientSecret
    let cartId = responseCartId
    let subId = responseSubId

    if (!responseCartId && !responseClientSecret && !responseSubId) {
      const { payload, type } = await dispatch(
        createSubscription({
          auth,
          paymentDetails,
          products,
          coupon: couponCode,
          taxId,
        })
      )

      cartId = payload.cart_id
      clientSecret = payload.items[0].client_secret
      subId = payload.items[0].subscription_id
      if (createSubscription.fulfilled.type === type) {
        const cardNumber = elements.getElement("cardNumber")
        if (cardNumber) {
          const options = {
            name: formValues.cardName,
            address: {
              postal_code: formValues.postalCode,
              line1: formValues.address1,
              line2: formValues.address2,
              city: formValues.city,
              state: formValues.state,
              country: formValues.country,
            },
            phone: formValues.phoneNumber,
          }

          const result = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
              card: cardNumber,
              billing_details: options,
            },
          })

          if (result.error) {
            updateBackDropVisible(false)
            handleStripeErrors(result)
            setIsDisabled(false)

            setResponseCartId("")
            setResponseClientSecret("")
            setResponseSubId("")
            return
          }

          const pmId = result?.paymentIntent?.payment_method
          if (pmId) {
            const res = await dispatch(
              attachSubscription({
                auth,
                cartId,
                subId,
                pmId,
              })
            )

            if (res.type === "/attachSubscription/rejected") {
              // TODO: Display error message
              updateBackDropVisible(false)
              return
            }

            // Display payment confirmation message
            setOpen(true)

            const items = products.map((product) => ({
              item_name: `${product?.name}`,
              item_id: `${product?.id}`,
              price: (product?.amount / 100).toFixed(2),
              item_category: ``,
              item_category2: ``,
              quantity: "1",
            }))

            let productDetails

            if (cartProrateDetails) {
              productDetails = {
                value: (cartProrateDetails.sub_total / 100).toFixed(2),
                tax: (cartProrateDetails.tax / 100).toFixed(2),
                credit: (cartProrateDetails.credit / 100).toFixed(2),
              }
            } else if (cartDetails) {
              productDetails = {
                value: (cartDetails?.sub_total / 100).toFixed(2),
                tax: (cartDetails.tax / 100).toFixed(2),
                credit: "0",
              }
            }

            window.dataLayer.push({
              event: "checkoutSuccess",
              price: `Total price: ${productDetails?.value}`,
              ecommerce: {
                value: `${productDetails?.value}`,
                credit: `${productDetails?.credit}`,
                tax: `${productDetails?.tax}`,
                transaction_id: `${res.payload.invoice_id}`,
                coupon,
                items,
              },
            })
          }
        }
      }
    }
  }

  const handleStripeErrors = (result: any) => {
    if (result.error) {
      if (result.error) {
        if (
          ["card_declined", "processing_error", "incomplete_number"].includes(
            result.error?.code!
          ) &&
          result.error.message
        ) {
          setStripeState({
            ...stripeState,
            cardNumberError: result.error.message,
            expiredError: "",
            cvcError: "",
          })
        }

        if (
          ["incomplete_expiry", "expired_card"].includes(result.error?.code!) &&
          result.error.message
        ) {
          setStripeState({
            ...stripeState,
            expiredError: result.error.message,
            cardNumberError: "",
            cvcError: "",
          })
        }

        if (
          ["incorrect_cvc", "incomplete_cvc"].includes(result.error?.code!) &&
          result.error.message
        ) {
          setStripeState({
            ...stripeState,
            cvcError: result.error.message,
            cardNumberError: "",
            expiredError: "",
          })
        }
      }

      updateBackDropVisible(false)
    }
  }

  const attachCard = async (pmId: any) => {
    const res = await dispatch(updatePaymentMethod({ pmId, auth }))

    // Return to payment info on new checkout cart site
    if (
      res.payload.status &&
      updateCardFormVisible &&
      getPaymentInfo &&
      !widget
    ) {
      getPaymentInfo()
      updateCardFormVisible(false)
      setIsDisabled(false)
    }
    // Return to payment info section on widget within EE
    else if (
      res.payload.status &&
      updateCardFormVisible &&
      getPaymentInfo &&
      widget
    ) {
      getPaymentInfo()
      updateCardFormVisible(false)
      setCookie("scrollTop", "true")
      setIsDisabled(false)
      window.location.reload()
    }

    updateBackDropVisible(false)
  }

  const digitsOnly = (value: any) => /^\d+$/.test(value)

  const validationSchema = Yup.object().shape({
    cardName: Yup.string()
      .required("Required")
      .max(25, "Name on card must be less than 25 characters"),
    address1: Yup.string()
      .required("Required")
      .max(80, "Address line 1 must be less than 80 characters"),
    address2: Yup.string().max(
      80,
      "Address line 2 must be less than 80 characters"
    ),
    city: Yup.string()
      .required("Required")
      .max(50, "City must be less than 50 characters"),
    state: Yup.string()
      .required("Required")
      .max(50, "State must be less than 50 characters"),
    country: Yup.string().required("Required"),
    postalCode: Yup.string().max(10).required("Required"),
    phoneNumber: Yup.string()
      .test("Digits only", "The field should have digits only", digitsOnly)
      .length(10, "Phone number must be 10 characters")
      .required("Required"),
    phoneExtension: Yup.string().max(
      5,
      "Phone extension must be less than 5 characters"
    ),
  })

  const validateNumber = (e: any, limit: number) => {
    let newValue = e.target.value
    if (newValue.length <= limit) {
      newValue = newValue
        // eslint-disable-next-line
        .replace(/[^,0-9]/g, "")
    } else {
      newValue = newValue.substring(0, limit)
    }

    e.target.value = newValue
  }

  const validateZipCodeFormat = (e: any, limit: number) => {
    let newValue = e.target.value
    if (newValue.length <= limit) {
      newValue = newValue
        .toUpperCase()
        // eslint-disable-next-line
        .replace(/[^,[a-z,A-Z,0-9,-]/g, "")
    } else {
      newValue = newValue.substring(0, limit)
    }

    e.target.value = newValue
  }

  const onFieldChange = useCallback(
    (fieldName: string, value: string | boolean) => {
      setFormValues((prevValues: PaymentDetails) =>
        update(prevValues, {
          [fieldName]: {
            $set: value,
          },
        })
      )
    },
    []
  )

  const handleSetTouched = (field: string) => {
    setTouched({
      ...touched,
      [field]: true,
    })
  }

  const validateFields = async (submitting?: boolean) => {
    await validationSchema
      .validate(formValues, { abortEarly: false })
      .then(() => {
        if (taxErrorActive) {
          setFormErrors({
            cardName: "",
            address2: "",
            country: "",
            postalCode: "",
            phoneNumber: "",
            phoneExtension: "",
            address1: formErrors.address1,
            city: formErrors.city,
            state: formErrors.state,
          })
          return
        } else if (zipCodeErrorActive) {
          setFormErrors({
            ...formErrors,
            cardName: "",
            address1: "",
            address2: "",
            city: "",
            state: "",
            country: "",
            postalCode: formErrors.postalCode,
            phoneNumber: "",
            phoneExtension: "",
          })
          return
        }

        setFormErrors({
          ...formErrors,
          cardName: "",
          address1: "",
          address2: "",
          city: "",
          state: "",
          country: "",
          postalCode: "",
          phoneNumber: "",
          phoneExtension: "",
        })
        setTaxErrorActive(false)
        setZipCodeErrorActive(false)

        setIsDisabled(false)
      })
      .catch((err) => {
        const errors = err.inner.reduce((acc: any, error: any) => {
          return {
            ...acc,
            [error.path]:
              touched[error.path] || submitting ? error.message : "",
          }
        }, {})

        // display address error as well if still true
        if (taxErrorActive) {
          setFormErrors({
            ...errors,
            ...formErrors,
            address1:
              "Please verify that your address, city, and state are correct.",
            city: "Please verify that your address, city, and state are correct.",
            state:
              "Please verify that your address, city, and state are correct.",
          })
        } else if (zipCodeErrorActive) {
          if (errors.postalCode) {
            setFormErrors({ ...formErrors, ...errors })
            return
          }
          setFormErrors({ ...formErrors })
        } else {
          // display schema requirement errors only

          const { address1, city, state, postalCode, phoneNumber } = formValues
          if (address1 && city && postalCode && phoneNumber && !state) {
            setFormErrors({ ...formErrors, state: "Required" })
          } else {
            setFormErrors(errors)
          }
        }

        setIsDisabled(true)
      })
  }

  const checkTaxes = async (recalculateProration?: boolean) => {
    const { address1, city, state, postalCode, country } = formValues

    if (recalculateProration) {
      recalculateTaxes()
      return
    }

    if (
      (address1 &&
        city &&
        state &&
        postalCode &&
        country &&
        // items is for cart, subscriptions is for widget
        ((items && items.length > 0) ||
          (subscriptions && subscriptions.length > 0)) &&
        (addressRef.current !== address1 ||
          cityRef.current !== city ||
          stateRef.current !== state ||
          postalRef.current !== postalCode ||
          countryRef.current !== country ||
          (!taxErrorActive &&
            (formErrors.address1 ||
              formErrors.city ||
              formErrors.state ||
              formErrors.postalCode ||
              formErrors.country)) ||
          (!zipCodeErrorActive &&
            formErrors.postalCode &&
            postalCode.length >= 3))) ||
      widget
    ) {
      const address = {
        address1,
        address2: "",
        city,
        state,
        zip: postalCode,
        country,
      }
      setIsDisabled(true)

      // if zip code is the field being update do not recalculate tax since zip code is not used for tax calculation

      addressRef.current = address1
      cityRef.current = city
      stateRef.current = state
      countryRef.current = country

      let doNotSkipTaxCalcuations = true

      // skip only if change is related to postal code
      if (
        (Object.keys(postalRef.current).length > 0 &&
          addressRef.current !== address1) ||
        cityRef.current !== city ||
        stateRef.current !== state
      ) {
        doNotSkipTaxCalcuations = postalRef.current === postalCode
      }

      // skip tax calculation if
      if (doNotSkipTaxCalcuations) {
        postalRef.current = postalCode

        const cartItems: any = []
        if (items) {
          items.forEach((product: any) => {
            const item = {
              id: product.id,
              price: (product.amount / 100).toFixed(2),
              qty: "1",
              name: product.name,
            }

            cartItems.push(item)
          })
        } else if (subscriptions && subscriptions.length) {
          const subscription = subscriptions.filter((subscription) =>
            subscription?.name.includes("ARE 5.0")
          )

          subscription.forEach((product: any) => {
            const item = {
              id: product.price_id,
              price: (product.base_price / 100).toFixed(2),
              qty: "1",
              name: product.name,
            }
            cartItems.push(item)
          })
        } else if (widget) {
          const qaENV =
            window.location.href.includes("-qa") ||
            window.location.href.includes("local")

          const placeholderItem = {
            id: qaENV
              ? "price_1OCxtz267oeCP8Yt7eGMltdR"
              : "price_1ODTxI267oeCP8YtywLcIRxd",
            price: "599.00",
            qty: "1",
            name: "ARE 5.0 Expert 1-Month Subscription Firm",
          }
          cartItems.push(placeholderItem)
        }

        try {
          const fields = {
            items: cartItems,
            coupon: couponCode,
            address,
          }

          if (widget) {
            const { type, payload }: any = await dispatch(updatePrice(fields))
            if (type !== "/reprice/fulfilled") {
              setTaxErrorActive(true)
              setFormErrors({ ...formErrors, ...validationErrors })
              return
            }

            setTaxId(payload.tax_rate_id)
          }
          // calculation proration
          else if (
            subscriptions &&
            subscriptions.length > 0 &&
            auth.isAuthenticated
          ) {
            subscriptions.forEach((subscription: any) => {
              if (subscription.name.indexOf("ARE 5.0") >= 0) {
                fields.items[0]["target_subscription_id"] = subscription.id
              }
            })

            const { type, payload } = await dispatch(
              getProration({ auth, ...fields })
            )

            // Display errors and do not enable form button
            if (type !== "/prorate/fulfilled") {
              setTaxErrorActive(true)
              setFormErrors({ ...formErrors, ...validationErrors })
              return
            }

            setTaxId(payload.tax_rate_id)
          }
          // calculation reprice
          else {
            const { type, payload }: any = await dispatch(updatePrice(fields))

            // Display error and do not enable form button
            if (type !== "/reprice/fulfilled") {
              setTaxErrorActive(true)
              setFormErrors({ ...formErrors, ...validationErrors })
              return
            }

            setTaxId(payload.tax_rate_id)
          }

          setTaxErrorActive(false)

          // validate zip code after taxes
          // clear avalara errors if any were displayed
          validateZipCode(postalCode, city, state, country, false)
        } catch (error) {
          return
        }
      } else {
        postalRef.current = postalCode
        // skip tax calculation if zip code is the field being updated
        // show avalara errors if any were displayed
        validateZipCode(postalCode, city, state, country, true)
      }
    }
  }

  // Recalculate Proration based on original payment method if user cancels card update
  const recalculateTaxes = async () => {
    if (
      subscriptions &&
      subscriptions.length > 0 &&
      auth.isAuthenticated &&
      items
    ) {
      const paymentMethod = billingInfoStoreState?.payment_methods[0].address

      const address = {
        address1: paymentMethod?.address_1,
        address2: paymentMethod?.address_2,
        city: paymentMethod?.city,
        state: paymentMethod?.state,
        postal_code: paymentMethod?.postal_code,
        country: paymentMethod?.country,
      }

      const cartItems: any = []

      items.forEach((product: any) => {
        const item = {
          id: product.id,
          price: (product.amount / 100).toFixed(2),
          qty: "1",
          name: product.name,
        }
        cartItems.push(item)
      })

      const fields = {
        items: cartItems,
        coupon: couponCode,
        address,
      }

      subscriptions.forEach((subscription: any) => {
        if (subscription.name.indexOf("ARE 5.0") >= 0) {
          fields.items[0]["target_subscription_id"] = subscription.id
        }
      })

      await dispatch(getProration({ auth, ...fields }))
    }
  }

  const validate = async () => {
    await validateFields()
    checkTaxes()
  }

  const handleClick = () => {
    window.dataLayer.push({ event: "termsAndConditions" })

    window.open(
      process.env.REACT_APP_TERMS_AND_CONDITIONS_URL ||
        "https://go.blackspectacles.com/terms-and-conditions",
      "_blank"
    )
  }

  const handleCancel = () => {
    if (updateCardFormVisible) updateCardFormVisible(false)

    if (billingInfoStoreState?.payment_methods[0]) {
      checkTaxes(true)
    } else {
      clearOutCalculations()
    }
    // prorate with existing payment method
  }

  const clearOutCalculations = async () => {
    const cartItems: any = []

    if (items) {
      items.forEach((product: any) => {
        const item = {
          id: product.id,
          price: (product.amount / 100).toFixed(2),
          qty: "1",
          name: product.name,
        }

        cartItems.push(item)
      })

      const fields = {
        items: cartItems,
        coupon: couponCode,
        address: {},
      }

      await dispatch(updatePrice(fields))
    }
  }

  const validateZipCode = (
    postalCode: string,
    city: string,
    state: string,
    country: string,
    errorActive?: boolean
  ) => {
    if (country === "US") {
      const postalCodeData = zipcodes.lookup(postalCode)

      if (!postalCodeData) {
        setFormErrors({
          cardName: "",
          address1: errorActive ? formErrors.address1 : "",
          address2: "",
          city: errorActive ? formErrors.city : "",
          state: errorActive ? formErrors.state : "",
          country: "",
          postalCode: "Invalid postal code.",
          phoneNumber: "",
          phoneExtension: "",
        })

        setZipCodeErrorActive(true)
        setIsDisabled(true)
        return false
      }

      const stateLongName =
        stateAbbreviations[postalCodeData.state].toLowerCase()

      if (
        postalCodeData.state.toLowerCase() === state.toLowerCase() ||
        stateLongName === state.toLowerCase()
      ) {
        setFormErrors({
          cardName: "",
          address1: errorActive ? formErrors.address1 : "",
          address2: "",
          city: errorActive ? formErrors.city : "",
          state: errorActive ? formErrors.state : "",
          country: "",
          postalCode: "",
          phoneNumber: "",
          phoneExtension: "",
        })
        setZipCodeErrorActive(false)
        setIsDisabled(false)
        return true
      } else if (
        postalCodeData.state.toLowerCase() !== state.toLowerCase() &&
        stateLongName !== state.toLowerCase()
      ) {
        setFormErrors({
          cardName: "",
          address1: errorActive ? formErrors.address1 : "",
          address2: "",
          city: "",
          state: errorActive ? formErrors.state : "",
          country: "",
          postalCode: "Postal code does not match state.",
          phoneNumber: "",
          phoneExtension: "",
        })
      }

      setZipCodeErrorActive(true)
      setIsDisabled(true)
      return false
    }

    setZipCodeErrorActive(false)
    return true
  }

  return (
    <>
      <CheckoutBox>
        <CheckoutHeader>
          <Typography variant="h3">
            {updateBillingForm
              ? "Step 3: Update Billing Info"
              : "Step 2: Secure Checkout"}
          </Typography>
        </CheckoutHeader>
        <form
          id="billingForm"
          method="POST"
          onSubmit={(e) => {
            updateBillingForm ? handleBillingUpdate(e) : handlePaymentSubmit(e)
          }}
        >
          <FormBox>
            <CardInformationLabel variant="h4">
              1. Card information
              <img src={CreditIcons} alt={"credit card icons"} />
            </CardInformationLabel>
            <BorderedBox boxPosition={1}>
              <FormRow className="formRowSpacing">
                <TextField
                  label={<Label text={"Name on card"} required={true} />}
                  placeholder={"John Smith"}
                  name={"cardName"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => onFieldChange("cardName", e.target.value)}
                  onBlur={() => validateFields()}
                  errorMessage={formErrors?.cardName}
                  onFocus={() => handleSetTouched("cardName")}
                />
                <StripeTextFieldNumber
                  label={
                    <Label
                      text={"Credit or debit card number"}
                      required={true}
                    />
                  }
                  name={"cardNumber"}
                  variant={"filled"}
                  error={Boolean(cardNumberError)}
                  labelErrorMessage={cardNumberError}
                  onChange={onElementChange(
                    "cardNumberComplete",
                    "cardNumberError"
                  )}
                />
              </FormRow>
              <FormRow className="formRowSpacing">
                <StripeTextFieldExpiry
                  label={<Label text={"Expiration date"} required={true} />}
                  name={"cardExpiry"}
                  variant={"filled"}
                  error={Boolean(expiredError)}
                  labelErrorMessage={expiredError}
                  onChange={onElementChange("expiredComplete", "expiredError")}
                />
                <StripeTextFieldCVC
                  label={<Label text={"Security code"} required={true} />}
                  name={"securityCode"}
                  variant={"filled"}
                  error={Boolean(cvcError)}
                  labelErrorMessage={cvcError}
                  onChange={onElementChange("cvcComplete", "cvcError")}
                />
              </FormRow>
            </BorderedBox>
            <BillingDetailsLabel variant="h4">
              2. Billing details
            </BillingDetailsLabel>
            <BorderedBox boxPosition={2}>
              <FormRow className="formRowSpacing">
                <TextField
                  id="address1"
                  label={<Label text={"Address line 1"} required={true} />}
                  placeholder={"Street address"}
                  name={"address1"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => onFieldChange("address1", e.target.value)}
                  onBlur={() => validate()}
                  errorMessage={formErrors?.address1}
                  onFocus={() => handleSetTouched("address1")}
                />
                <TextField
                  id="address2"
                  label={<Label text={"Address line 2"} />}
                  placeholder={"Apt, suite, etc. (optional)"}
                  name={"address2"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => onFieldChange("address2", e.target.value)}
                  onBlur={() => validateFields()}
                  errorMessage={formErrors?.address2}
                  onFocus={() => handleSetTouched("address2")}
                />
              </FormRow>

              <FormRow className="formRowSpacing">
                <TextField
                  id="city"
                  label={<Label text={"City"} required={true} />}
                  placeholder={"Chicago"}
                  name={"city"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => onFieldChange("city", e.target.value)}
                  onBlur={() => validate()}
                  errorMessage={formErrors?.city}
                  onFocus={() => handleSetTouched("city")}
                />

                {formValues.country !== "US" ? (
                  <TextField
                    id="state"
                    label={<Label text={"State"} required={true} />}
                    placeholder={"IL"}
                    name={"state"}
                    variant={"filled"}
                    fullWidth={true}
                    onChange={(e) => onFieldChange("state", e.target.value)}
                    onBlur={() => validate()}
                    errorMessage={formErrors?.state}
                    onFocus={() => handleSetTouched("state")}
                  />
                ) : (
                  <SelectField
                    id="state"
                    label={<Label text={"State"} required={true} />}
                    name={"state"}
                    onFieldChange={onFieldChange}
                    validateFields={() => validate()}
                    errorMessage={formErrors?.state}
                    handleSetTouched={handleSetTouched}
                    options={stateOptions}
                    variant={"filled"}
                    value={formValues.state}
                  />
                )}
              </FormRow>

              <FormRow className="formRowSpacing">
                <SelectField
                  id="country"
                  label={<Label text={"Country"} required={true} />}
                  name={"country"}
                  onFieldChange={onFieldChange}
                  validateFields={() => validate()}
                  errorMessage={formErrors?.country}
                  handleSetTouched={handleSetTouched}
                  options={countryOptions}
                  variant={"filled"}
                  value={formValues.country}
                />

                <TextField
                  id="postalCode"
                  label={<Label text={"Postal code"} required={true} />}
                  placeholder={"60611"}
                  name={"postalCode"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => {
                    validateZipCodeFormat(e, 10)
                    onFieldChange("postalCode", e.target.value)
                  }}
                  onBlur={() => validate()}
                  errorMessage={formErrors?.postalCode}
                  onFocus={() => handleSetTouched("postalCode")}
                />
              </FormRow>

              <FormRow className="formRowSpacing">
                <TextField
                  label={<Label text={"Phone number"} required={true} />}
                  placeholder={"1234567890"}
                  name={"phoneNumber"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => {
                    validateNumber(e, 10)
                    onFieldChange("phoneNumber", e.target.value)
                  }}
                  onBlur={() => validateFields()}
                  errorMessage={formErrors?.phoneNumber}
                  onFocus={() => handleSetTouched("phoneNumber")}
                />

                <TextField
                  label={<Label text={"Extension"} />}
                  placeholder={"1"}
                  name={"phoneExtension"}
                  variant={"filled"}
                  fullWidth={true}
                  onChange={(e) => {
                    validateNumber(e, 5)
                    onFieldChange("phoneExtension", e.target.value)
                  }}
                  onBlur={() => validateFields()}
                  errorMessage={formErrors?.phoneExtension}
                  onFocus={() => handleSetTouched("phoneExtension")}
                />
              </FormRow>
            </BorderedBox>
            {(updateBillingForm && subscriptions && subscriptions.length) ||
            (billingInfoStoreState &&
              billingInfoStoreState?.payment_methods &&
              billingInfoStoreState.payment_methods.length) ||
            widget ? null : (
              <BlueBox>
                <Typography variant="body1">
                  <input
                    type="checkbox"
                    required
                    style={{ marginRight: 10 }}
                  ></input>
                  I understand that any subscription(s) will automatically renew
                  at the start of each billing cycle, and I may cancel at any
                  time.
                </Typography>

                <br />

                <Typography variant="body1">
                  By clicking Pay now, you agree to Black Spectacles{" "}
                  <BlueLabel onClick={handleClick}>
                    Terms and Conditions
                  </BlueLabel>
                  .
                </Typography>
              </BlueBox>
            )}
          </FormBox>
          <ButtonBox>
            <Button
              className="formSubmitButton"
              color={"primary"}
              // Display Save for users that do have a subscription
              children={
                (updateBillingForm && subscriptions && subscriptions.length) ||
                (billingInfoStoreState &&
                  billingInfoStoreState?.payment_methods &&
                  billingInfoStoreState.payment_methods.length) ||
                widget
                  ? "Save"
                  : "Pay now"
              }
              size={"large"}
              type={"submit"}
              // disabled={
              //   // isDisabled ||
              //   // !stripeState.cardNumberComplete ||
              //   // !stripeState.expiredComplete ||
              //   // !stripeState.cvcComplete
              // }
            />

            {updateBillingForm && updateCardFormVisible ? (
              <Button
                className="formCancelButton"
                color={"whiteGreyBorder"}
                children={"Cancel"}
                size={"large"}
                onClick={() => {
                  handleCancel()
                }}
              />
            ) : null}
          </ButtonBox>
        </form>
      </CheckoutBox>

      {open && isAREProduct ? <ConfirmationModal modalOpen={open} /> : null}

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={backDropVisible}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  )
}

export default View
